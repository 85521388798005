import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import clsx from 'clsx';
import Button from 'components/button/Button';
import { Pill, PillsWrapper } from 'components/pill/Pill';
import Tooltip from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useLocation } from 'react-router';

type IconAlignment = 'top' | 'title' | 'center';

export interface TemplateProps {
    title: ReactNode;
    description?: string;
    status?: ReactNode;
    statusPill?: ReactNode;
    learnMoreLink?: string;
    icon?: ReactNode;
    iconAlignment?: IconAlignment;
    type?: string;
    postTitleActions?: ReactNode;
    actions?: ReactNode;
    tags?: string[];
    flex?: boolean;
    sticky?: boolean;
    equalPadding?: boolean;
    truncateDescription?: boolean;
    children?: ReactNode;
    isNarrowLayout?: boolean;
    className?: string;
}

const Template: FC<TemplateProps> = ({
    title,
    description,
    learnMoreLink,
    icon,
    iconAlignment = 'top',
    type,
    postTitleActions,
    actions,
    tags,
    flex,
    sticky,
    equalPadding,
    truncateDescription,
    status,
    statusPill,
    children,
    isNarrowLayout,
    className
}) => {
    const [titleRef, titleIsOverflowing] = useOverflowing<HTMLHeadingElement>();
    const [descriptionRef, descriptionIsOverflowing] = useOverflowing<HTMLParagraphElement>();

    const { pathname } = useLocation();
    const isLightBackground = ['/settings/', '/explorer/', '/scopes/', '/scope/', '/monitoring/', '/status'].some(
        (lightPagePath) => pathname.includes(lightPagePath)
    );

    const iconAlignmentClasses: Record<IconAlignment, string> = {
        top: 'items-start',
        title: 'h-[40.5px] items-center',
        center: 'items-center'
    };

    return (
        <section className={cn(isNarrowLayout && '-mx-6', flex && 'flex flex-col h-full', className)}>
            <header
                className={cn(
                    'pt-[20px] flex',
                    isNarrowLayout && 'pl-6',
                    isLightBackground ? 'bg-backgroundSecondary' : 'bg-backgroundPrimary',
                    sticky && 'sticky top-0 z-50',
                    equalPadding ? 'pb-[20px]' : 'pb-[32px]'
                )}
            >
                {icon && <div className={cn('flex mr-4 shrink-0', iconAlignmentClasses[iconAlignment])}>{icon}</div>}
                <div className='flex-1 min-w-0'>
                    <div className='flex items-center h-[40.5px] w-full'>
                        <div className='flex items-center flex-1 min-w-0' role='heading' aria-level={1}>
                            <Tooltip
                                title={title}
                                disabled={!titleIsOverflowing}
                                className={cn('w-full truncate', { 'w-auto': Boolean(postTitleActions) })}
                            >
                                {isNarrowLayout ? (
                                    <Text.H2 className='w-full h-full truncate' ref={titleRef}>
                                        {title}
                                    </Text.H2>
                                ) : (
                                    <Text.H1 className='w-full h-full truncate' ref={titleRef}>
                                        {title}
                                    </Text.H1>
                                )}
                            </Tooltip>
                            {postTitleActions}
                        </div>
                        {actions && (
                            <div className='flex items-center justify-end ml-4 shrink-0' data-testid='actions'>
                                {actions}
                            </div>
                        )}
                    </div>
                    {description && (
                        <Tooltip title={description} disabled={!truncateDescription || !descriptionIsOverflowing}>
                            <Text.Body
                                className={clsx('mt-2 text-textSecondary', truncateDescription && 'truncate')}
                                ref={descriptionRef}
                                data-testid='description'
                            >
                                {description}
                                {learnMoreLink && (
                                    <Button variant='link' className='ml-1' href={learnMoreLink}>
                                        Learn more
                                    </Button>
                                )}
                            </Text.Body>
                        </Tooltip>
                    )}
                    {status && <div className='py-2'>{status}</div>}
                    <PillsSection tags={tags} type={type} statusPill={statusPill}>
                        <TemplateType type={type} />
                        {statusPill}
                        <TemplateTags tags={tags} />
                    </PillsSection>
                </div>
            </header>
            {children}
        </section>
    );
};

export default Template;

const PillsSection = ({
    tags,
    type,
    statusPill,
    children
}: PropsWithChildren<Pick<TemplateProps, 'tags' | 'type' | 'statusPill'>>) => {
    if (!tags && !type && !statusPill) {
        return null;
    }

    return (
        <PillsWrapper className='mt-3 flex-nowrap' data-testid='tags-container'>
            {children}
        </PillsWrapper>
    );
};

const TemplateType = ({ type }: Pick<TemplateProps, 'type'>) => {
    if (!type) {
        return null;
    }

    return (
        <Pill data-testid='type' className='capitalize'>
            {type}
        </Pill>
    );
};

const TemplateTags = ({ tags }: Pick<TemplateProps, 'tags'>) => {
    if (!tags || tags.length < 0) {
        return null;
    }

    const tagsSet = new Set(tags.sort((a, b) => a.localeCompare(b)));

    return (
        <PillsWrapper.Truncate
            items={[...tagsSet].map((tag) => (
                <Pill key={tag} variant='outline' className='font-normal'>
                    {tag}
                </Pill>
            ))}
        />
    );
};
